import React, { memo } from 'react';
import { Trans } from 'react-i18next';

type ShowMoreProps = {
  onShowMore: () => Promise<void>;
};

const ShowMoreComments = memo(({ onShowMore }: ShowMoreProps) => {
  return (
    <div className="comments__show-more" onClick={onShowMore}>
      <Trans i18nKey="social:feed_item_message_info_show_all_comments" />
    </div>
  );
});

export default ShowMoreComments;
